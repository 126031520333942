*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: Mulish, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

* {
  touch-action: manipulation;
}

html {
  height: 100%;
}

body {
  --tw-bg-opacity: 1;
  background-color: rgb(243 248 254 / var(--tw-bg-opacity) );
}

.dark body {
  --tw-bg-opacity: 1;
  background-color: rgb(16 16 18 / var(--tw-bg-opacity) );
}

body {
  --tw-text-opacity: 1;
  color: rgb(48 55 87 / var(--tw-text-opacity) );
  font-size: 1rem;
  line-height: 1.5rem;
}

.dark body {
  --tw-text-opacity: 1;
  color: rgb(239 239 246 / var(--tw-text-opacity) );
}

@media (min-width: 472px) {
  body {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

a {
  cursor: pointer;
}

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

a, input, button {
  outline-offset: 2px;
  border-radius: .375rem;
  outline: 2px solid #0000;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

input {
  border-width: 1px;
  border-color: #0000;
}

input:focus {
  border-color: rgb(40 120 241 / var(--tw-border-opacity) );
  --tw-border-opacity: 1;
}

a:focus-visible, button:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(40 120 241 / var(--tw-ring-opacity) );
}

.dark a:focus-visible, .dark button:focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(147 197 253 / var(--tw-ring-opacity) );
}

*, :before, :after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2878f180;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2878f180;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.header-panel, .footer-panel {
  height: 60px;
  background-color: rgb(251 253 255 / var(--tw-bg-opacity) );
  --tw-bg-opacity: .9;
  --tw-backdrop-blur: blur(16px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
}

.dark .header-panel, .dark .footer-panel {
  background-color: rgb(69 72 82 / var(--tw-bg-opacity) );
  --tw-bg-opacity: .3;
}

@media (min-width: 472px) {
  .header-panel, .footer-panel {
    height: 60px;
    border-radius: 9999px;
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    padding: 1.25rem 3rem;
  }
}

@media (min-width: 1536px) {
  .header-panel, .footer-panel {
    width: 100%;
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
  }
}

.panel-container {
  max-width: 72rem;
  flex-grow: 1;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.button-link {
  align-items: center;
  display: flex;
}

.button-link > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse) );
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.button-link {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(40 120 241 / var(--tw-border-opacity) );
  border-radius: 9999px;
  padding: .5rem 1.25rem;
}

.button-link:hover {
  --tw-border-opacity: 1;
  border-color: rgb(98 156 244 / var(--tw-border-opacity) );
}

.dark .button-link {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity) );
}

.button-link {
  --tw-text-opacity: 1;
  color: rgb(40 120 241 / var(--tw-text-opacity) );
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.25rem;
}

.button-link:hover {
  --tw-text-opacity: 1;
  color: rgb(98 156 244 / var(--tw-text-opacity) );
}

.dark .button-link {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
}

.button-link {
  filter: drop-shadow(0 0 #0000);
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.button-link:hover {
  filter: drop-shadow(0 4px 10px #3870ffcc);
}

.dark .button-link:hover {
  filter: drop-shadow(0 4px 15px #fffc);
}

.main-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 2.5rem 1rem;
}

@media (min-width: 472px) {
  .main-container {
    max-width: 400px;
    padding: 54px 0;
  }
}

.card {
  height: 100%;
  width: 100%;
  border-radius: 1.375rem;
  flex-direction: column;
  justify-content: flex-end;
  padding: 1.5rem;
  display: flex;
}

@media (min-width: 472px) {
  .card {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.card {
  --tw-bg-opacity: 1;
  background-color: rgb(70 72 198 / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
  background-clip: padding-box;
  border-width: 2px;
  border-color: #0000;
  position: relative;
}

.card > :not(div) {
  z-index: 2;
}

.card:before {
  content: "";
  z-index: -1;
  border-radius: inherit;
  background-image: radial-gradient(37.09% 87.54% at .41% -1.78%, #fff 0%, #fff0 100%), linear-gradient(258.65deg, #dde2f3 33.29%, #eff2f9 48.38%);
  margin: -2px;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.dark .card:before {
  background-image: radial-gradient(37.09% 87.54% at .41% -1.78%, #fff 0%, #fff0 100%), linear-gradient(258.65deg, #8c94b080 33.29%, #babcc2d9 48.38%);
}

#card-wrapper {
  perspective: 1000px;
  position: relative;
}

#card-wrapper:before {
  content: "";
  width: 90%;
  height: 82px;
  opacity: .25;
  filter: blur(20px);
  background: linear-gradient(92.68deg, #2878f1 62.15%, #5e9eff 112.21%);
  border-radius: 42px;
  display: block;
  position: absolute;
  bottom: -14px;
  left: 5%;
  right: 5%;
}

#card-wrapper-inner {
  aspect-ratio: 440 / 265;
  width: 100%;
  transform-style: preserve-3d;
  transition: transform .8s;
  position: relative;
  transform: translate3d(0, 0, 0);
}

@supports not (aspect-ratio: auto) {
  #card-wrapper-inner {
    padding-top: 60.23%;
  }
}

#card-wrapper.flipped .card-front, #card-wrapper:hover .card-front {
  pointer-events: none;
}

.card-front:after, .card-back:after {
  content: "";
  background-color: inherit;
  z-index: -1;
  pointer-events: none;
  border-radius: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.card-gradient, .card-waves {
  pointer-events: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.card-gradient {
  z-index: 0;
  background-image: url("gradient.343d425f.svg");
}

.domain {
  white-space: nowrap;
}

h2.domain {
  background: #fff5;
  border-radius: .75rem;
  outline: none;
  padding: 0 .5rem;
  font-weight: normal;
  overflow: hidden;
  cursor: text !important;
}

#card-input {
  width: 100%;
  background-color: rgb(33 32 44 / var(--tw-bg-opacity) );
  --tw-bg-opacity: .6;
  border-radius: .625rem;
  padding: 1rem;
  line-height: 1rem;
  display: block;
}

#copy-tooltip {
  background-color: rgb(33 32 44 / var(--tw-bg-opacity) );
  --tw-bg-opacity: .95;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
  pointer-events: none;
  opacity: 0;
  border-radius: .625rem;
  justify-content: center;
  align-items: center;
  transition-property: opacity;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#flip-button {
  --tw-bg-opacity: 1;
  background-color: rgb(40 120 241 / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
  border-radius: 9999px;
  padding: .25rem .375rem;
  display: block;
  position: relative;
}

#flip-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 101 255 / var(--tw-bg-opacity) );
}

#flip-button:active {
  --tw-bg-opacity: 1;
  background-color: rgb(0 71 178 / var(--tw-bg-opacity) );
}

#flip-button:before {
  height: 100%;
  width: 100%;
  --tw-content: "";
  content: var(--tw-content);
  --tw-shadow: 0 0 20px 0 #0066ff80;
  --tw-shadow-colored: 0 0 20px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 9999px;
  position: absolute;
  top: 0;
  left: 0;
}

.dark #flip-button:before {
  content: var(--tw-content);
  --tw-shadow: 0 0 20px 0 #0066ffb3;
  --tw-shadow-colored: 0 0 20px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#flip-button:before {
  z-index: -1;
  opacity: 0;
  content: var(--tw-content);
  transition-property: opacity;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

#flip-button:hover:before {
  content: var(--tw-content);
  opacity: 1;
}

.main-panel {
  border-width: 1px;
  border-color: rgb(40 120 241 / var(--tw-border-opacity) );
  --tw-border-opacity: .15;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
}

.dark .main-panel {
  border-color: rgb(202 204 223 / var(--tw-border-opacity) );
  --tw-border-opacity: .2;
  background-color: rgb(69 72 82 / var(--tw-bg-opacity) );
  --tw-bg-opacity: .3;
}

.main-form {
  border-radius: 9999px;
  grid-template-columns: 40px 1fr 40px;
  align-items: center;
  gap: .625rem;
  padding-left: .5rem;
  padding-right: .5rem;
  display: grid;
  overflow: hidden;
}

@media (min-width: 472px) {
  .main-form {
    grid-template-columns: 40px 6.5ch 40px 1fr;
    padding-right: .75rem;
  }
}

.plus-minus-button {
  height: 40px;
  width: 40px;
  --tw-text-opacity: 1;
  color: rgb(40 120 241 / var(--tw-text-opacity) );
  background-color: #0000;
  border-radius: 0;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin-top: -1px;
  margin-bottom: -1px;
  padding: .25rem;
  display: flex;
}

.plus-minus-button:hover {
  background-color: rgb(239 239 246 / var(--tw-bg-opacity) );
  --tw-bg-opacity: .7;
}

.plus-minus-button:active {
  background-color: rgb(38 79 141 / var(--tw-bg-opacity) );
  --tw-bg-opacity: .15;
  --tw-text-opacity: 1;
  color: rgb(40 120 241 / var(--tw-text-opacity) );
}

.dark .plus-minus-button {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
}

.dark .plus-minus-button:hover {
  --tw-bg-opacity: .1;
}

.dark .plus-minus-button:active {
  background-color: rgb(0 0 0 / var(--tw-bg-opacity) );
  --tw-bg-opacity: .25;
  --tw-text-opacity: 1;
  color: rgb(40 120 241 / var(--tw-text-opacity) );
}

.plus-minus-button:disabled {
  cursor: not-allowed;
  opacity: .5;
}

#amount-input {
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  background-color: #0000;
  display: block;
}

@media (min-width: 472px) {
  #amount-input {
    max-width: 6.5ch;
  }
}

#amount-input {
  text-align: center;
}

#converted-amount {
  position: absolute;
  top: -1.5rem;
  left: 0;
}

@media (min-width: 472px) {
  #converted-amount {
    text-align: right;
    position: static;
    top: 0;
    overflow: hidden;
  }
}

#converted-amount {
  white-space: nowrap;
  color: rgb(38 79 141 / var(--tw-text-opacity) );
  --tw-text-opacity: .6;
  font-size: .75rem;
  line-height: 1rem;
}

.dark #converted-amount {
  color: rgb(239 239 246 / var(--tw-text-opacity) );
  --tw-text-opacity: .4;
}

#converted-amount {
  opacity: 0;
  transition-property: opacity, color;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

#converted-amount.visible {
  opacity: 1;
}

#converted-amount.flash {
  --tw-text-opacity: 1;
  color: rgb(40 120 241 / var(--tw-text-opacity) );
  transition-duration: .15s;
}

.dark #converted-amount.flash {
  --tw-text-opacity: 1;
  color: rgb(147 197 253 / var(--tw-text-opacity) );
}

#submit-button {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
  background-color: #0000;
  border-radius: 9999px;
  padding: .625rem 2rem;
  font-weight: 800;
  line-height: 1.25rem;
  position: relative;
}

#submit-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(98 156 244 / var(--tw-bg-opacity) );
}

#submit-button:active {
  --tw-bg-opacity: 1;
  background-color: rgb(25 96 203 / var(--tw-bg-opacity) );
}

#submit-button:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(239 239 246 / var(--tw-bg-opacity) );
  color: rgb(38 79 141 / var(--tw-text-opacity) );
  --tw-text-opacity: .6;
}

.dark #submit-button:active {
  --tw-bg-opacity: 1;
  background-color: rgb(0 71 178 / var(--tw-bg-opacity) );
}

.dark #submit-button:disabled {
  --tw-bg-opacity: .6;
  color: rgb(239 239 246 / var(--tw-text-opacity) );
  --tw-text-opacity: .6;
}

#submit-button {
  --tw-shadow: 0px 5px 15px #3a85f657;
  --tw-shadow-colored: 0px 5px 15px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

#submit-button:disabled {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#submit-button:before {
  height: 100%;
  width: 100%;
  --tw-content: "";
  background-image: linear-gradient(to right, var(--tw-gradient-stops) );
  --tw-gradient-from: #2878f1 62.15%;
  --tw-gradient-to: #fff0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: #5e9eff 112.21%;
  z-index: -1;
  content: var(--tw-content);
  border-radius: 9999px;
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  top: 0;
  left: 0;
}

#submit-button:disabled:before {
  content: var(--tw-content);
  opacity: 0;
}

#message-input-label:before, #message-input-label:after {
  pointer-events: none;
  height: 1.25rem;
  width: 1.25rem;
  opacity: 0;
  --tw-gradient-from: #fff;
  --tw-gradient-to: #fff0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: transparent;
  display: block;
  position: absolute;
}

.dark #message-input-label:before, .dark #message-input-label:after {
  --tw-gradient-from: #212228;
  --tw-gradient-to: #21222800;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#message-input-label:before, #message-input-label:after {
  content: "";
}

#message-input-label:before {
  background-image: linear-gradient(to right, var(--tw-gradient-stops) );
  bottom: .625rem;
  left: 1rem;
}

#message-input-label:after {
  background-image: linear-gradient(to left, var(--tw-gradient-stops) );
  bottom: .625rem;
  right: 1rem;
}

#message-input {
  width: 100%;
  resize: none;
  border-radius: .75rem;
  padding: 2.25rem 1rem .625rem;
  font-size: .875rem;
  font-weight: 300;
  line-height: 1.25rem;
}

#message-input:focus {
  border-color: rgb(40 120 241 / var(--tw-border-opacity) );
  --tw-border-opacity: 1;
}

#message-input::placeholder {
  color: rgb(38 79 141 / var(--tw-text-opacity) );
  --tw-text-opacity: .6;
}

.dark #message-input::placeholder {
  color: rgb(239 239 246 / var(--tw-text-opacity) );
  --tw-text-opacity: .3;
}

#message-char-count {
  color: rgb(38 79 141 / var(--tw-text-opacity) );
  --tw-text-opacity: .6;
  font-size: .75rem;
  line-height: 1rem;
  position: absolute;
  top: .625rem;
  right: 1rem;
}

.dark #message-char-count {
  color: rgb(202 204 223 / var(--tw-text-opacity) );
  --tw-text-opacity: .5;
}

#message-char-count {
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

#message-char-count.limit-reached {
  --tw-text-opacity: 1;
  color: rgb(253 186 116 / var(--tw-text-opacity) );
}

.dark #message-char-count.limit-reached {
  color: rgb(251 146 60 / var(--tw-text-opacity) );
  --tw-text-opacity: .6;
}

.dark-mode-button {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(210 222 240 / var(--tw-bg-opacity) );
  border-radius: 9999px;
  flex-shrink: 0;
  padding: 3px 28px 3px .25rem;
  display: flex;
  position: relative;
}

.dark .dark-mode-button {
  --tw-bg-opacity: 1;
  background-color: rgb(69 72 82 / var(--tw-bg-opacity) );
}

.dark-mode-button-icons {
  pointer-events: none;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: absolute;
  top: 3px;
  left: .25rem;
  right: .25rem;
}

.dark-mode-button-icons:before, .dark-mode-button-icons:after {
  content: "";
  width: 20px;
  height: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: block;
}

.dark-mode-button-icons:before {
  background-image: url("sun.005b5d21.svg");
}

.dark-mode-button-icons:after {
  background-image: url("moon.185b13d5.svg");
}

.dark-mode-button-pin {
  pointer-events: none;
  height: 1.25rem;
  width: 1.25rem;
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
  border-radius: 9999px;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: block;
}

.dark .dark-mode-button-pin {
  --tw-translate-x: 1.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  --tw-bg-opacity: 1;
  background-color: rgb(219 219 219 / var(--tw-bg-opacity) );
}

.mobile-nav {
  z-index: 50;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 11rem 2rem 2rem;
  display: flex;
  position: fixed;
  left: 0;
  right: 0;
}

@media (min-width: 472px) {
  .mobile-nav {
    display: none;
  }
}

.mobile-nav {
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
  --tw-bg-opacity: .9;
  --tw-backdrop-blur: blur(8px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.dark .mobile-nav {
  background-color: rgb(16 16 18 / var(--tw-bg-opacity) );
  --tw-bg-opacity: .9;
}

.dark .flip-button-decoration {
  background-image: url("ellipse-dark.99e4a9a0.svg");
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
}

.top-2\.5 {
  top: .625rem;
}

.left-4 {
  left: 1rem;
}

.top-2 {
  top: .5rem;
}

.right-0 {
  right: 0;
}

.-top-12 {
  top: -3rem;
}

.right-2 {
  right: .5rem;
}

.top-0 {
  top: 0;
}

.-mr-2 {
  margin-right: -.5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mt-auto {
  margin-top: auto;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-4 {
  height: 1rem;
}

.h-\[60px\] {
  height: 60px;
}

.h-\[28px\] {
  height: 28px;
}

.min-h-full {
  min-height: 100%;
}

.w-\[60px\] {
  width: 60px;
}

.w-auto {
  width: auto;
}

.flex-grow {
  flex-grow: 1;
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.grid-cols-\[1fr_140px\] {
  grid-template-columns: 1fr 140px;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-2\.5 {
  gap: .625rem;
}

.gap-2 {
  gap: .5rem;
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse) );
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.text-center {
  text-align: center;
}

.text-2xl {
  font-size: 1.625rem;
  line-height: 2rem;
}

.text-5xl {
  font-size: 2.625rem;
  line-height: 3.125rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.font-extrabold {
  font-weight: 800;
}

.font-semibold {
  font-weight: 600;
}

.font-medium {
  font-weight: 500;
}

.font-bold {
  font-weight: 700;
}

.leading-5 {
  line-height: 1.25rem;
}

.tracking-wider {
  letter-spacing: .0625rem;
}

.text-slate-500 {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity) );
}

.text-slate-850 {
  --tw-text-opacity: 1;
  color: rgb(38 79 141 / var(--tw-text-opacity) );
}

.text-slate-600 {
  --tw-text-opacity: 1;
  color: rgb(102 108 126 / var(--tw-text-opacity) );
}

.text-opacity-60 {
  --tw-text-opacity: .6;
}

.opacity-0 {
  opacity: 0;
}

.\!opacity-100 {
  opacity: 1 !important;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition-colors {
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

#spot-top-wrapper, #spot-bottom-wrapper {
  pointer-events: none;
  max-width: 72rem;
  margin-left: auto;
  margin-right: auto;
  position: fixed;
}

#spot-bottom-wrapper {
  bottom: -120px;
  left: 0;
  right: 0;
}

#spot-bottom {
  width: 380px;
  height: 150px;
  filter: blur(101px);
  background: linear-gradient(92.22deg, #bfddff 48.13%, #d9ffcc 84.91%);
  border-radius: 100%;
  margin: 0 auto;
}

.dark #spot-bottom {
  background: linear-gradient(90deg, #489cffb3 8.78%, #60ecffb3 83.06%);
}

@media (min-width: 768px) {
  #spot-top, #spot-bottom {
    width: 500px;
    height: 250px;
  }

  #spot-top-wrapper {
    top: -150px;
    left: 0;
    right: 0;
  }

  #spot-top {
    filter: blur(178px);
    --spot-translate-limit: -100%;
    background: linear-gradient(92.22deg, #bfddff 48.13%, #d9ffcc 84.91%);
    margin-left: auto;
  }

  .dark #spot-top {
    background: linear-gradient(90deg, #489cffad 8.78%, #60ecffad 83.06%);
  }

  #spot-bottom-wrapper {
    bottom: -150px;
  }

  #spot-bottom {
    --spot-translate-limit: 100%;
    margin: 0;
  }
}

@media (min-width: 1200px) {
  #spot-top {
    --spot-translate-limit: -150%;
  }

  #spot-bottom {
    --spot-translate-limit: 150%;
  }
}

@media (prefers-reduced-motion) {
  #spot-top, #spot-bottom {
    animation: none;
  }
}

@keyframes spotAnimation {
  0% {
    transform: translate3d(0, 0, 0);
  }

  50% {
    transform: translate3d(var(--spot-translate-limit), 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

#address-input {
  font-size: 16px;
}

.hover\:text-blue-500:hover {
  --tw-text-opacity: 1;
  color: rgb(40 120 241 / var(--tw-text-opacity) );
}

.dark .dark\:text-slate-200 {
  --tw-text-opacity: 1;
  color: rgb(226 232 240 / var(--tw-text-opacity) );
}

.dark .dark\:text-slate-150 {
  --tw-text-opacity: 1;
  color: rgb(239 239 246 / var(--tw-text-opacity) );
}

.dark .dark\:text-inherit {
  color: inherit;
}

.dark .dark\:text-opacity-50 {
  --tw-text-opacity: .5;
}

@media (min-width: 472px) {
  .sm\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:h-5 {
    height: 1.25rem;
  }

  .sm\:grid-cols-\[1fr_150px\] {
    grid-template-columns: 1fr 150px;
  }

  .sm\:text-left {
    text-align: left;
  }

  .sm\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

@media (min-width: 600px) {
  .md\:flex {
    display: flex;
  }

  .md\:justify-between {
    justify-content: space-between;
  }
}

@media (min-width: 1024px) {
  .lg\:h-\[30px\] {
    height: 30px;
  }
}

/*# sourceMappingURL=index.c7b28f38.css.map */
