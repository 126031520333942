@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    * {
        touch-action: manipulation;
    }

    html {
        @apply h-full;
    }

    body {
        @apply bg-slate-100 dark:bg-slate-900;
        @apply text-base text-slate-800 dark:text-slate-150;
        @apply sm:py-4;
    }

    a {
        @apply cursor-pointer;
    }

    /* Remove default arrows on number inputs */
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        appearance: none;
    }

    a,
    input,
    button {
        @apply transition-colors ease-in-out duration-200;
        @apply outline-none rounded-md;
    }

    input {
        @apply border border-transparent;
        @apply focus:border-blue-500 focus:border-opacity-100;
    }

    a,
    button {
        @apply focus-visible:ring-2 focus-visible:ring-blue-500;
        @apply dark:focus-visible:ring-blue-300;
    }
}

@layer components {
    .header-panel,
    .footer-panel {
        @apply px-4 h-[60px] flex items-center;
        @apply bg-slate-50 dark:bg-slate-700 bg-opacity-90 dark:bg-opacity-30 backdrop-blur-lg;
        @apply sm:px-12 sm:py-5 sm:mx-5 sm:rounded-full sm:h-[60px];
        @apply 2xl:w-full 2xl:max-w-[1500px] 2xl:mx-auto;
    }

    .panel-container {
        @apply flex-grow flex items-center max-w-6xl mx-auto;
    }

    .button-link {
        @apply flex items-center space-x-2 px-5 py-2;
        @apply border border-blue-500 hover:border-blue-400 dark:border-white rounded-full;
        @apply text-sm font-bold text-blue-500 hover:text-blue-400 dark:text-white;
        @apply transition-all;

        filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
    }

    .button-link:hover {
        filter: drop-shadow(0px 4px 10px rgba(56, 112, 255, 0.8));
        /* text-shadow: 0px 4px 15px rgba(0, 71, 254, 0.69); */
    }

    .dark .button-link:hover {
        filter: drop-shadow(0px 4px 15px rgba(255, 255, 255, 0.8));
        /* text-shadow: 0px 4px 15px rgba(255, 255, 255, 0.69); */
    }

    .main-container {
        @apply px-4 py-10 sm:py-[54px] sm:px-0 w-full sm:max-w-[400px] mx-auto;
    }

    .card {
        @apply w-full h-full p-6 rounded-2xl flex flex-col justify-end sm:py-8;
        @apply bg-indigo-800 text-white;
        @apply border-2 border-transparent relative bg-clip-padding;
    }

    .card > *:not(div) {
        z-index: 2;
    }

    /* Inspired by https://css-tricks.com/gradient-borders-in-css/ */
    .card::before {
        content: "";
        z-index: -1;
        border-radius: inherit;
        background-image: radial-gradient(
                37.09% 87.54% at 0.41% -1.78%,
                #ffffff 0%,
                rgba(255, 255, 255, 0) 100%
            ),
            linear-gradient(258.65deg, #dde2f3 33.29%, #eff2f9 48.38%);

        @apply block absolute left-0 right-0 top-0 bottom-0 -m-[2px];
    }

    .dark .card::before {
        background-image: radial-gradient(
                37.09% 87.54% at 0.41% -1.78%,
                #ffffff 0%,
                rgba(255, 255, 255, 0) 100%
            ),
            linear-gradient(
                258.65deg,
                rgba(140, 148, 176, 0.5) 33.29%,
                rgba(186, 188, 194, 0.85) 48.38%
            );
    }

    #card-wrapper {
        perspective: 1000px;
        position: relative;
    }

    #card-wrapper::before {
        content: "";
        display: block;
        position: absolute;
        left: 5%;
        right: 5%;
        bottom: -14px;
        width: 90%;
        height: 82px;

        background: linear-gradient(92.68deg, #2878f1 62.15%, #5e9eff 112.21%);
        opacity: 0.25;
        filter: blur(20px);
        border-radius: 42px;
    }

    #card-wrapper-inner {
        @apply w-full aspect-card;

        position: relative;
        transition: transform 0.8s;
        transform: translate3d(0, 0, 0);
        transform-style: preserve-3d;
    }

    @supports not (aspect-ratio: auto) {
        #card-wrapper-inner {
            /* Equal to 265 / 440 */
            padding-top: 60.23%;
        }
    }

    #card-wrapper:not(.flipped):not(:hover) .card-back {
        @apply pointer-events-none;
    }

    #card-wrapper.flipped .card-front,
    #card-wrapper:hover .card-front {
        @apply pointer-events-none;
    }

    /* Fix for border gradient somehow applying to the whole card when transformed */
    .card-front::after,
    .card-back::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: inherit;
        border-radius: 20px;
        z-index: -1;
        pointer-events: none;
    }

    .card-gradient,
    .card-waves {
        @apply absolute left-0 right-0 top-0 bottom-0 pointer-events-none;

        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 20px;
    }

    .card-gradient {
        z-index: 0;
        background-image: url("./assets/gradient.svg");
    }

    .card-waves {
        z-index: 0;
        background-image: url("./assets/line-waves.svg");
    }

    .domain {
        @apply whitespace-nowrap;
    }

    h2.domain {
        background: #ffffff55;
        border-radius: 0.75rem;
        padding: 0 0.5rem;
        outline: none;
        cursor: text !important;
        overflow: hidden;
        font-weight: normal;
    }

    #card-input {
        @apply block w-full rounded-lg p-4 leading-4;
        @apply bg-[#21202C] bg-opacity-60;
    }

    #copy-tooltip {
        @apply absolute left-0 right-0 top-0 bottom-0;
        @apply flex items-center justify-center rounded-lg;
        @apply bg-[#21202C] bg-opacity-95 text-white;
        @apply transition-opacity duration-300 opacity-0 pointer-events-none;
    }

    .card-button {
        @apply flex-1 flex items-center justify-center space-x-1.5 rounded-lg p-4;
        @apply font-extrabold text-sm whitespace-nowrap;
        @apply bg-white text-blue-500 hover:bg-blue-500 hover:text-white active:bg-blue-600 active:text-white;
    }

    #flip-button {
        @apply block py-1 px-1.5 rounded-full relative;
        @apply bg-blue-500 hover:bg-blue-700 active:bg-blue-800 text-white;

        @apply before:content-[''] before:absolute before:left-0 before:top-0 before:w-full before:h-full before:rounded-full;
        @apply before:shadow-flip-button dark:before:shadow-flip-button-dark;
        @apply before:opacity-0 before:transition-opacity before:duration-200 before:z-[-1];
        @apply hover:before:opacity-100;
    }

    #flip-button::before {
    }

    .main-panel {
        @apply border border-blue-500 border-opacity-15 bg-white;
        @apply dark:bg-slate-700 dark:bg-opacity-30 dark:border-slate-400 dark:border-opacity-20;
    }

    .main-form {
        @apply grid grid-cols-form sm:grid-cols-form-large gap-2.5 items-center rounded-full overflow-hidden px-2 sm:pr-3;
    }

    .plus-minus-button {
        @apply w-[40px] h-[40px] flex-shrink-0 p-1 -my-[1px] flex items-center justify-center;
        @apply bg-transparent text-blue-500 rounded-none;
        @apply hover:bg-slate-150 hover:bg-opacity-70 active:bg-slate-850 active:bg-opacity-15 active:text-blue-500;
        @apply dark:text-white;
        @apply dark:hover:bg-opacity-10 dark:active:bg-black dark:active:bg-opacity-25 dark:active:text-blue-500;
        @apply disabled:cursor-not-allowed disabled:opacity-50;
    }

    #amount-input {
        @apply appearance-none block w-full sm:max-w-[6.5ch] bg-transparent;
        @apply text-center;
    }

    #converted-amount {
        @apply absolute -top-6 left-0 sm:static sm:top-0 sm:overflow-hidden sm:text-right;
        @apply text-xs whitespace-nowrap text-slate-850 text-opacity-60 dark:text-slate-150 dark:text-opacity-40;
        @apply transition-[opacity,_color] duration-1000 opacity-0;
    }

    #converted-amount.visible {
        @apply opacity-100;
    }

    #converted-amount.flash {
        @apply duration-150 text-blue-500 dark:text-blue-300;
    }

    #submit-button {
        /* --tw-gradient-stops: #2878f1 62.15%, #5e9eff 112.21%; */

        @apply py-2.5 px-8 rounded-full relative;
        @apply text-white leading-5 font-extrabold;
        @apply bg-transparent hover:bg-blue-400 active:bg-blue-600;
        @apply disabled:bg-slate-150 disabled:text-slate-850 disabled:text-opacity-60;
        @apply dark:active:bg-blue-800;
        @apply dark:disabled:bg-opacity-60 dark:disabled:text-slate-150 dark:disabled:text-opacity-60;
        @apply shadow-submit-button transition-all disabled:shadow-none;

        @apply before:content-[''] before:absolute before:left-0 before:top-0 before:w-full before:h-full before:rounded-full;
        @apply before:bg-gradient-to-r before:from-[#2878f1_62.15%] before:to-[#5e9eff_112.21%];
        @apply before:z-[-1] disabled:before:opacity-0 before:transition-opacity;
    }

    #message-input-label::before,
    #message-input-label::after {
        @apply block absolute w-5 h-5 pointer-events-none opacity-0;
        @apply from-white dark:from-[#212228] to-transparent;

        content: "";
    }

    #message-input-label::before {
        @apply left-4 bottom-2.5;
        @apply bg-gradient-to-r;
    }

    #message-input-label::after {
        @apply right-4 bottom-2.5;
        @apply bg-gradient-to-l;
    }

    #message-input-label.overflow-left::before,
    #message-input-label.overflow-right::after {
        @apply opacity-100;
    }

    #message-input {
        @apply w-full resize-none rounded-xl p-4 pb-2.5 pt-9;
        @apply text-sm font-light;
        @apply focus:border-blue-500 focus:border-opacity-100;
        @apply placeholder:text-slate-850 placeholder:text-opacity-60;
        @apply dark:placeholder:text-slate-150 dark:placeholder:text-opacity-30;
    }

    #message-char-count {
        @apply absolute top-2.5 right-4 text-xs leading-4 text-slate-850 text-opacity-60 dark:text-slate-400 dark:text-opacity-50;
        @apply transition-colors;
    }

    #message-char-count.limit-reached {
        @apply text-orange-300 dark:text-orange-400 dark:text-opacity-60;
    }

    .dark-mode-button {
        @apply relative flex flex-shrink-0 py-[3px] pl-1 pr-[28px];
        @apply rounded-full cursor-pointer;
        @apply bg-blue-200;
        @apply dark:bg-slate-700;
    }

    .dark-mode-button-icons {
        @apply absolute top-[3px] left-1 right-1 pointer-events-none;
        @apply flex items-center justify-between;
    }

    .dark-mode-button-icons::before,
    .dark-mode-button-icons::after {
        content: "";
        display: block;
        width: 20px;
        height: 20px;
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
    }

    .dark-mode-button-icons::before {
        background-image: url("./assets/sun.svg");
    }

    .dark-mode-button-icons::after {
        background-image: url("./assets/moon.svg");
    }

    .dark-mode-button-pin {
        @apply pointer-events-none block h-5 w-5 rounded-full;
        @apply transform translate-x-0 bg-white;
        @apply transition ease-in-out duration-200;

        @apply dark:translate-x-6 dark:bg-slate-300;
    }

    .mobile-nav {
        @apply fixed z-50 left-0 right-0 w-full h-full p-8 pt-44 flex flex-col items-center sm:hidden;
        @apply bg-white bg-opacity-90 dark:bg-slate-900 dark:bg-opacity-90 backdrop-blur;
    }

    .flip-button-decoration {
        @apply absolute w-[180px] h-[48px] bottom-2.5;

        background-image: url("./assets/ellipse-light.svg");
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
        z-index: -1;
    }

    .dark .flip-button-decoration {
        background-image: url("./assets/ellipse-dark.svg");
    }
}

#spot-top-wrapper,
#spot-bottom-wrapper {
    position: fixed;
    pointer-events: none;

    @apply max-w-6xl mx-auto;
}

#spot-bottom-wrapper {
    bottom: -120px;
    left: 0;
    right: 0;
}

#spot-bottom {
    width: 380px;
    height: 150px;
    margin: 0 auto;
    border-radius: 100%;

    background: linear-gradient(92.22deg, #bfddff 48.13%, #d9ffcc 84.91%);
    filter: blur(101px);
}

.dark #spot-bottom {
    background: linear-gradient(
        90deg,
        rgba(72, 156, 255, 0.7) 8.78%,
        rgba(96, 236, 255, 0.7) 83.06%
    );
}

@media (min-width: 768px) {
    #spot-top,
    #spot-bottom {
        width: 500px;
        height: 250px;
    }

    #spot-top-wrapper {
        top: -150px;
        left: 0;
        right: 0;
    }

    #spot-top {
        background: linear-gradient(92.22deg, #bfddff 48.13%, #d9ffcc 84.91%);
        filter: blur(178px);
        margin-left: auto;

        --spot-translate-limit: -100%;
    }

    .dark #spot-top {
        background: linear-gradient(
            90deg,
            rgba(72, 156, 255, 0.68) 8.78%,
            rgba(96, 236, 255, 0.68) 83.06%
        );
    }

    #spot-bottom-wrapper {
        bottom: -150px;
    }

    #spot-bottom {
        margin: 0;

        --spot-translate-limit: 100%;
    }
}

@media (min-width: 1200px) {
    #spot-top {
        --spot-translate-limit: -150%;
    }

    #spot-bottom {
        --spot-translate-limit: 150%;
    }
}

@media (prefers-reduced-motion) {
    #spot-top,
    #spot-bottom {
        animation: none;
    }
}

@keyframes spotAnimation {
    0% {
        transform: translate3d(0, 0, 0);
    }

    50% {
        transform: translate3d(var(--spot-translate-limit), 0, 0);
    }

    100% {
        transform: translate3d(0, 0, 0);
    }
}

#address-input {
    font-size: 16px;
}
